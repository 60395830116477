import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { GridColumn, GridRow } from '../../responsive/atoms/Grid';
import { CardItemVerticalLegacy } from '../../responsive/organisms/CardItemVerticalLegacy';
import {
  mapContentToCardData,
  removeAuthorsAndDateFromCardData
} from '../../../domain/utils/contentUtils';
import { ContentPackageWrapper } from '../../responsive/organisms/ContentPackageWrapper';
import { ContainerFluid } from '../../responsive/atoms/ContainerFluid';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';

const DynamicPackageInner = props => {
  const { data, isAboveTheFold, content } = props;
  const styles = {
    header: css`
      font-size: 2rem;
      line-height: 2.25rem;
      margin: 0;
      padding: 40px 40px 40px 60px;
      position: relative;
      font-weight: ${theme.font.weight('base')};
      text-align: unset;
      ${theme.mq.tablet} {
        font-size: 2.5rem;
        line-height: 3.25rem;
        padding: 40px 0 30px 100px;
        width: 750px;
      }
      ${theme.mq.small_desktop} {
        font-size: 3.125rem;
        line-height: 3.25rem;
        padding: 40px 70px 22px 100px;
        width: 839px;
      }
      ${theme.mq.large_desktop} {
        font-size: 3.75rem;
        line-height: 3.25rem;
        padding-bottom: 30px;
        width: 1265px;
      }

      &::before {
        background: #fc0;
        content: '';
        display: block;
        height: 68px;
        left: 30px;
        position: absolute;
        top: 0;
        width: 1px;
        ${theme.mq.tablet} {
          height: 78px;
          left: 60px;
        }
        ${theme.mq.small_desktop} {
          height: 83px;
        }
        ${theme.mq.large_desktop} {
          height: 86px;
        }
      }
    `
  };

  const contentBackgroundColor = content.backgroundColor
    ? content.backgroundColor
    : data.backgroundColor || 'black';
  return (
    <ContentPackageWrapper
      contentPackageHeader={data.packageName}
      backgroundColor={
        contentBackgroundColor === 'black'
          ? 'true_black'
          : contentBackgroundColor
      }
      extraStyles={css`
        ${facePaint({
          'contain-intrinsic-size': [
            '0 1720px',
            '0 403px',
            '0 405px',
            '0 470px'
          ]
        })}
      `}
      headerStyles={styles.header}
    >
      <ContainerFluid>
        <GridRow gutter="none">
          {data.dynamicPackageContent?.map((card, index) => {
            const key = `cardGlobalMedium2_${index}`;
            if (index <= 2 && card.entity) {
              return (
                <GridColumn
                  width={[12, 4, 4, 4]}
                  childrenMarginBottom="l"
                  key={key}
                >
                  <CardItemVerticalLegacy
                    size="third"
                    data={removeAuthorsAndDateFromCardData(
                      mapContentToCardData(card.entity)
                    )}
                    backgroundColor={contentBackgroundColor}
                    isAboveTheFold={isAboveTheFold}
                    canBeAnimated={index === 1} // Only animated in middle position
                    imageMaxWidths={{
                      mobile: 767,
                      tablet: 341,
                      small_desktop: 426,
                      large_desktop: 533
                    }}
                  />
                </GridColumn>
              );
            }
            return null;
          })}
        </GridRow>
      </ContainerFluid>
    </ContentPackageWrapper>
  );
};

DynamicPackageInner.defaultProps = {
  isAboveTheFold: false,
  content: undefined
};

DynamicPackageInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool,
  content: PropTypes.objectOf(PropTypes.any)
};

export const DynamicPackage = ParagraphWrapper(DynamicPackageInner);
